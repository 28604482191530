import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";

const ProfileMenu = (props: any) => {
  const { t } = useTranslation();

  const supportedProjects = useSelector(
    (state: any) => state.Project.supportedProjects.data
  );
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <ul className="profile-menu">
        <li>
          <a
            className={props.showBio ? "active pointer" : "pointer"}
            onClick={props.showBioHandler}
          >
            {t("Bio")}
          </a>
        </li>
        <li>
          <a
            className={props.showProjects ? "active pointer" : "pointer"}
            onClick={props.showProjectsHandler}
          >
            {t("Mesprojets")}{" "}
          </a>
        </li>
        <li            style={ t("dir") === "ltr"
              ? {  paddingRight: "6px" }
              : { paddingLeft: "6px" }
          }>
          <a
            className={props.showSupportedProjects ? "active pointer" : "pointer"}
            onClick={props.showSupportedProjectsHandler}
          >
            {t("Projets_Soutenus")}
          </a>
        </li>

        <li
          style={
            t("dir") === "rtl"
              ? { borderRight: "1px solid #ccc", paddingRight: "6px" }
              : { borderLeft: "1px solid #ccc", paddingLeft: "6px" }
          }
        >
          <a
            className={props.showWallet ? "active pointer" : "pointer"}
            onClick={props.showWalletHandler}
          >
            {t("Wallet")}
          </a>
        </li>
      </ul>
    </div>
  );
};
export default ProfileMenu;
