import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Button/Button";
import { getDonator, updateUser } from "../../../reducers/donator";
import { getTranscationsByUser } from "../../../reducers/wallet";
import Bio from "./components/Bio";
import CoverPicture from "./components/CoverPicture";
import ProfileDetails from "./components/ProfileDetails";
import ProfileMenu from "./components/ProfileMenu";
import Projects from "./components/Projects";
import SupportedProjects from "./components/SupportedProjects";
import Wallet from "./components/Wallet";
import jwt_decode from "jwt-decode";
import "./Profile.css";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { useNavigate } from "react-router-dom";
import { FooterComponent } from "../../../components/footer";

const getDonatorAction = async (dispatch: any, email: string) => {
  return await dispatch(getDonator(email)).unwrap();
};
const getTransactionsAction = async (dispatch: any, token: any) => {
  return await dispatch(getTranscationsByUser(token)).unwrap();
};
const updateUserAction = async (dispatch: any, userData: any) => {
  return await dispatch(updateUser(userData)).unwrap();
};
const Profile = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const donator = useSelector((state: any) => state.Donator.getDonator.data);
  const Transactions = useSelector(
    (state: any) => state.wallet.transcations.data
  );
  const [currentUserId, setCurrentuserId] = useState("");

  const getDonator = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      const res: any = jwt_decode(token);
      const email = res.email;
      const id = res.id;
      const wallet = res.walletPubKey;
      setCurrentuserId(id);

      await getDonatorAction(dispatch, email);
      await getTransactionsAction(dispatch, wallet);
    }
  };

  useEffect(() => {
    getDonator();
  }, []);

  const [showBio, setShowBio] = useState(true);
  const [showProjects, setShowProjects] = useState(false);
  const [showSupportedProjects, setShowSupportedProjects] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const [donatorData, setDonatorData] = useState();

  const showBioHandler = () => {
    setShowBio(true);
    setShowProjects(false);
    setShowSupportedProjects(false);
    setShowWallet(false);
  };
  const showProjectsHandler = () => {
    setShowBio(false);
    setShowProjects(true);
    setShowSupportedProjects(false);
    setShowWallet(false);
  };
  const showSupportedProjectsHandler = () => {
    setShowBio(false);
    setShowProjects(false);
    setShowSupportedProjects(true);
    setShowWallet(false);
  };
  const showWalletHandler = () => {
    setShowBio(false);
    setShowProjects(false);
    setShowSupportedProjects(false);
    setShowWallet(true);
  };

  const editUserHandler = async (userData: any) => {
    await updateUserAction(dispatch, userData);
    setDonatorData(userData);
  };

  useEffect(() => {
    setDonatorData(donator);
  }, [donator]);

  return (
    <>
      {donatorData && (
        <>
          <section
            className="mt-4 mb-4"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              cssClass="me-2"
              title={t("Créer_un_Projet")}
              onClick={() => navigate("/project-create")}
            />
          </section>
          <CoverPicture />
          <section id="contact_arae_main" className="mt-2" dir={t("dir")}>
            <div className="container">
              <div className="row">
                <div className="col-lg-4" style={{ height: "inherit" }}>
                  <ProfileDetails
                    user={donatorData ? donatorData : {}}
                    editUserHandler={editUserHandler}
                    id={currentUserId}
                  />
                </div>
                <div className="col-lg-8">
                  <div
                    className="contact_form_Wrapper"
                    style={{ height: "100%" }}
                  >
                    <ProfileMenu
                      showBio={showBio}
                      showProjects={showProjects}
                      showSupportedProjects={showSupportedProjects}
                      showWallet={showWallet}
                      showBioHandler={showBioHandler}
                      showProjectsHandler={showProjectsHandler}
                      showSupportedProjectsHandler={
                        showSupportedProjectsHandler
                      }
                      showWalletHandler={showWalletHandler}
                    />
                    {showBio && (
                      <Bio
                        user={donatorData}
                        editUserHandler={editUserHandler}
                        id={currentUserId}
                      />
                    )}
                    {showProjects && <Projects currentUserId={currentUserId} />}
                    {showSupportedProjects && (
                      <SupportedProjects currentUserId={currentUserId} />
                    )}
                    {showWallet && <Wallet Transactions={Transactions} />}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      <FooterComponent />
    </>
  );
};
export default Profile;
