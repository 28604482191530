import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ProjectItem from "../../../../components/ProjectItem/ProjectItem";
import { getRecentProjects } from "../../../../reducers/acceuil";
import "../../../../translations/i18n";
import './RecentProject.css';
const getRecentProjectssAction = async (dispatch: any, regions: string[]) => {
	return await dispatch(getRecentProjects(regions)).unwrap();
};
const RecentCampaigns = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
const today = new Date(); // Get today's date

const recentProjectsdata = useSelector((state: any) =>
  state.Acceuil.getrecentProjects.data ? state.Acceuil.getrecentProjects.data.value : []
);


  useEffect(() => {
		const regions: any = [];
		getRecentProjectssAction(dispatch, regions);
	}, [dispatch]);
  return (
   
    <section
    id="trending_causes"
    className="section_after section_padding bg-color mt-5 "
    dir={t("dir")}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-6 offset-lg-3 col-md-12 col-sm-12 col-12">
        <div className="title"><h1>{t("Soutenez_les_projets_en_cours")}</h1></div>
        <div className="line-cat">
              <img src="assets\img\about\RectangleBlue.png" alt="line"  />
              </div>
        <div className="SubHeader">
            <h2>{t("Les_campagnes_les_plus_récentes")}</h2>
          </div>
        </div>
      </div>
      <div
        className="row"
        id="counter"
        style={{ height: "10%", marginBottom: "20px" }}
      >
        {recentProjectsdata.length > 0 &&
          recentProjectsdata.map((item: any) => (
            <div
              key={item._id}
              className="col-lg-4 col-md-6 col-sm-12 col-12 mb-4 pb-2"
            >
              <ProjectItem campaign={item} />
            </div>
          ))}
       
      </div>

    </div>
  </section>
  );
};

export default RecentCampaigns;
