export const TRANSLATIONS_EN = {
  Projets: "Projets à soutenir",
  Details: "Details",
  Gallery: "Gallery",
  News: "News",
  Investments: "Investments",
  Contact: "Contact",
  Donate: "Donate",
  dir: "ltr",
  Liens_rapides: "Liens rapides",
  À_propos_de_nous: "À propos de nous",
  Communiquez_avec_nous: "Communiquez avec nous",
  Conditions_de_service: "Conditions de service",
  Supports: "Supports",
  Services: "Services",
  Accueil: "Accueil",
  Mesprojets: "Mes projets",
  Biblothèque: "Bibliothèque",
  A_propos: "A propos",
  Details_du_profile: "Details du profile",
  Paramètres: "Paramètres",
  Déconnexion: "Déconnexion",
  Login: "Se connecter",
  mon_profile: "Mon Profile",
  La_première: "La première plateforme",
  de: "de",
  crowdonation: " ‘’crowdonation’’ ",
  titre_description:
    "qui vise à renforcer la résilience communautaire en Tunisie en soutenant les initiatives entrepreneuriales et associatives. Elle utilise la technologie de blockchain pour garantir la transparence et la traçabilité maximales.",
  Faire_un_don_pour: "Faire un don pour",
  Environment: "Environment",
  Voir_plus: "Voir plus...",
  Pour_quoi_Antlike: "Pour quoi Ant’like ?",
  Inspirée: "Inspiré par les fourmis, des êtres uniques reconnus",
  planète: "pour leur capacité à optimiser",
  travail: "le travail dans des communautés décentralisées",
  Au_travers: "Au travers Ant’Like , nous cherchons à:",
  Renforcer: "Favoriser l'innovation sociale à travers la Tunisie.    ",
  Offrir_des_possibilités: "Offrir des alternatives de financement",
  aux_petites: " pour les projets entrepreneuriaux et sociaux.",
  Assurer_une_meilleure: "Améliorer le bien-être des citoyens tunisiens.",
  Comment_ça_marche: "Comment ça marche",
  inscrire:"Rejoignez dès maintenant Ant'Like pour stimuler l'innovation sociale et améliorer la  le bien-être des citoyens tunisiens !",
  porteur: "Espace pour les porteurs de projets",
  personne: "Espace pour les donateurs individuels",
  institution: "Espace pour les donateurs institutionnels",
  Les_campagnes_les_plus_récentes: "Les projets les plus récentes",
  Soutenez_les_projets_en_cours: "Soutenez les projets en cours",
  Antlike_est_un_consortium_entre_4_entités:
    "Ant’like est un consortium entre 4 entités",
  Financement_quadratique: "Opérations de financement quadratique ",
  Montant_total_collectés: "Montant total collecté",
  Donations_Faites: "Donations Faites",
  Campagnes_réalisées: "Campagnes réalisées",
  Les_campagnes_de_succès: "Les projets les plus performantes    ",
  Newsletter: "Newsletter",
  Pour_recevoir:
    "Rejoignez notre communauté, abonnez-vous à notre newsletter dès maintenant !    ",
  Abonnez_vous: "Abonnez-vous",
  objectif: "Objectif",
  campagne_exipred: "La campagne est expirée depuis",
  campagne_in_coming: "La campagne viendra dans",
  Jours: "Jours ",
  Date: "Date",
  region: "Région",
  Projets_Soutenus: "Projets Soutenus",
  Wallet: "Wallet",
  Bio: "Bio",
  Ajouter_une_biographie: "Ajouter une biographie",
  Ajouter_une_image: "Ajouter une image",
  Retirer: "Retirer",
  Liens: "Liens",
  Créer_un_Projet: "Créer un Projet",
  Informations_generales: "Étape 1 : Informations générales",
  Nom_du_Project: "Nom du projet",
  Descriptif_du_projet: "Descriptif du projet",
  Suivant: "Suivant",
  Questionnaire: "Étape 2",
  Durabilité: "Étape 2 : Durabilité ",
  précisions: "Veuillez donner les précisions à l'appui de votre réponse",
  projet_est_durable:
    "Comment évaluez-vous la durabilité de votre projet sur une échelle de 1 (faible) à 5 (élevé) ?  ",
  échelle:
    "Est-ce que votre projet et ses activités peuvent être maintenus sur le long terme pour créer de la valeur pour votre communauté et vos partenaires ?",
  Employabilité: "Étape 3 : Employabilité",
  chaque_sexe: "Veuillez répartir vos employés suivant leurs genres ?",
  Homme: "Homme",
  Femme: "Femme",
  tranche_âge:
    "Veuillez répartir vos employés suivant leurs tranches d'âge ?     ",
  Entre_18_30: "Entre 18 et 30 ans :",
  Entre_30_45: "Entre 30 et 45 ans :     ",
  Entre_46_55: "Entre 46 et 55 ans :",
  Entre_55: "> 55 ans :",
  Innovation: "Étape 4 : Innovation",
  innovant: "Pensez-vous que votre projet présente de l'innovation ? ",
  Exemple:
    "(p. ex. Innovation de produit, de processus, de commercialisation, d'organisation, etc.)",
  Unicité: "Étape 5 : Unicité",
  unique:
    "Est-ce que votre projet offre une proposition de valeur distinctive dans votre région ?",
  Impact: "Étape 6 : Impact sur l'environement     ",
  project_impact:
    "Comment évaluez-vous l'impact de votre projet sur l'environnement ?",
  Collaborataires_et_prestataires:
    "Étape 7 : Emplois indirects et les prestataires de services",
  Combien:
    " Quel est le nombre de prestataires ou de collaborateurs indirects que vous engagez pour votre projet ? (Veuillez les répartir vos employés suivant leurs genres)    ",
  Valeur_Ajoutée: "Étape 8 : Valeur ajoutée à la communauté",
  bénéficie:
    "Quel sera l'impact de votre projet sur la communauté et comment mesurerez-vous cet impact ?    ",
  Finaliser: "Étape 9 : Co-fondateur et équipe du projet",
  FinaliserPDF: "Étape 10 : Documents de support et contenu promotionnel. ",
  Co_fondateur: "Co-fondateur",
  ajouter_Co_fondateur:
    " Souhaitez-vous ajouter un co-fondateur ou un membre de l’équipe à votre projet ?    ",
  Ajouter_un_co_fondateur: "Ajouter un co-fondateur",
  Valider: "Valider",
  photos_vidéos_documents: "Ajout des photos/vidéos/documents",
  photos: "Veuillez attacher des photos liés à votre projet",
  Télécharger: "Télécharger",
  PDF: "Veuillez attacher un document (pdf) lié à votre projet",
  Video: "Veuillez attacher une vidéo liée à votre projet: (facultatif)    ",
  Terminer: "Terminer",
  Créer_un_projet: "Créer un projet",
  Félecitations: "Félicitation !    ",
  succes: "Votre projet a été crée avec succés !",
  Créateur: "Créateur de projet",
  Email: "Email",
  Gouvernorat: "Gouvernorat",
  Telephone: "Telephone",
  Financé_à: "Financé à",
  Contributeurs: "Contributeurs ",
  Jours_restants: "Jours restants ",
  Créer_une_campagne: "Créer une campagne",
  Détails_de_la_campagne: "Détails de la campagne    ",
  Donations_récentes: "Donations récentes",
  pas_de_donation: "pas de donation    ",
  Etape1_Campagne: "Étape 1 : Ajouter une campagne",
  Nom_de_la_campagne: "Nom de la campagne",
  Montant_à_collecter: "Montant à collecter",
  Date_de_début: "Date de début",
  Date_de_fin: "Date de fin",
  Durée: "Durée",
  Objectifs_de_la_compagne: "Objectifs de la compagne",
  photos_vidéos_documentsCampagne:
    "Étape 2 : Ajout des photos/vidéos/documents",
  CampagneVideo:
    "              Veuillez attacher une vidéo liée à votre campagne:    ",
  Campagnephotos: "Veuillez attacher des photos liés à votre campagne",
  CampagnePdf: "Veuillez attacher un document (pdf) lié à votre campagne",
  succes_campaign: "La campagne a été crée avec succés",
  Créer_un_Campagne: "Créer une campagne",
  Faire_un_don: "Faire un don",
  Faites_un_don_maintenant: "                    Faites un don maintenant    ",
  Montant: "Montant",
  sucessDonation: "Votre don se termine avec succès!",
  Historique_des_transactions: "Historique des transactions    ",
  Nom: "Nom",
  Connectez_vous: "Connectez-vous !",
  Mot_de_passe: "Mot de passe",
  Mot_de_passe_oublié: " Mot de passe oublié ? ",
  Inscrivez_vous_ici: "Inscrivez-vous ici    ",
  Email_Obligatoire: "L'email est obligatoire    ",
  Rejoignez_nous: "Rejoignez-nous, inscrivez-vous!    ",
  Donateur: "Donateur",
  Entreprise: "Entreprise",
  Nom_et_Prénom: "Nom et Prénom",
  Confirmer_mot_passe: "Confirmer mot de passe",
  Inscrivez_vous: "S'inscrire",
  ONG: "ONG",
  Nouvelle_entreprise: "Nouvelle entreprise",
  Gérant: "Gérant",
  Employé: "Employé",
  Nouvelle_ONG: "Nouvelle ONG   ",
  Nom_de_lEntreprise: "Nom de l'Entreprise",
  Nom_de_lONG: "Nom de  l'ONG",
  Choisir_une_entreprise: "Choisir une entreprise",
  Choisir_une_ONG: "Choisir une ONG",
  Télécharger_RNE: "Télécharger RNE",
  Télécharger_justificatif: "Télécharger justificatif",
  Télécharger_CIN_Recto: "Télécharger cin recto",
  Télécharger_CIN_verco: "Télécharger cin verco",
  Vérification: "Vérification de l'adresse email",
  Vérifier_votre_email: "            Vérifier votre email    ",
  Adresse_delentreprise: "Adresse de l'entreprise",
  historique_des_compagnes_quadratique:
    "            historique des compagnes quadratique    ",
  regions: "Régions",
  Secteurs: "Secteurs d'activité",
  attente: "Statut: En attente",
  Simulé: "Statut: Simulé",
  Accepté: "Statut: Accepté",
  Refusé: "Statut: Refusé",
  Auncu_campagne_quadratique: " Auncu campagne quadratique",
  Lancer_une_campagne_quadratique: "Lancer une campagne quadratique",
  Créer_une_campagne_quadratique:
    "            Créer une campagne quadratique    ",
  Selectionner:
    "Veuillez sélectionner des régions et/ou des secteurs d'activité pour créer une campagne quadratique.    ",
  Régions_sélectionnées: "Régions sélectionnées",
  Secteurs_dactivité_sélectionnés: "Secteurs d'activité sélectionnés",
  Sélectionner_des_régions: "Sélectionner des régions",
  Sélectionner_des_secteurs_activité: "Sélectionner des secteurs d'activité",
  Réinitialiser: "Réinitialiser",
  Confirmer: "Confirmer",
  Campagne: "Campagne",
  Projet: "Projet",
  Montant_total: "Montant total",
  Progression: "Progression en %",
  simulation: "Faire une simulation",
  Montant_Donné: "Montant Donné",
  Annuler: "Annuler",
  Ajouter_Somme: "Ajouter la somme à contribuer en DT",
  Obligatoire: "Obligatoire",
  Télecharger_PDF: "Télecharger PDF",
  Nombre_des_employés: "Nombre des employés",
  Nombre_des_femmes: "Nombre des femmes",
  Nombre_des_hommes: "Nombre des hommes",
  email_est_obligatoire: "L'email est obligatoire",
  Le_mot_de_passe_est_obligatoire: "Le mot de passe est obligatoire    ",
  Veuillez_confirmer_votre_adresse_email:
    "Veuillez confirmer votre adresse email",
  Vérifier_votre_email_et_mot_de_passe: "Vérifier votre email et mot de passe",
  Ce_champ_est_obligatoire: "Ce champ est obligatoire",
  Veuillez_remplir_tous_les_champs: "Veuillez remplir tous les champs",
  Lemail_nest_pas_valide: "L'email n'est pas valide",
  mdp: "Votre mot de passe doit comporter au moins 6 caractères et doit inclure une combinaison de chiffres, de lettres et de caractères spéciaux (!@%)",
  mdp_necorrespondpas: "Votre mot de passe ne correspond pas",
  validation_de_votre_compte:
    "Vous avez reçu un e-mail de validation de votre compte",
  Veuillez_taper_au_moins_50_mots: "Veuillez taper au moins 50 mots",
  Il_faut_ajouter_au_moins_une_image_à_votre_campagne:
    "Il faut ajouter au moins une image à votre campagne",
  Il_faut_ajouter_un_document_pdf_à_votre_campagne:
    "Il faut ajouter un document pdf à votre campagne",
  Il_faut_ajouter_une_vidéo_à_votre_campagne:
    "Il faut ajouter une vidéo à votre campagne",
  caractére_numérique: "Veuillez taper un caractére numérique (0,1,2,3,4,ect)",
  Il_faut_ajouter_au_moins_une_image_à_votre_project:
    "Il faut ajouter au moins une image à votre projet",
  Il_faut_ajouter_un_document_pdf_à_votre_project:
    "Il faut ajouter un document pdf à votre projet    ",
  Profession: "Profession",
  Aucun_Projet: "Aucun Projet",
  campaign_number: "Nombre des campagnes",
  Nombre_des_collaborators: "Nombre des collaborators",
  co_founder: "Co-fondateur",
  collected_amount: "Montant collecté",
  category: "Catégorie",
  remaining_days: "Jours restants",
  Veuillez_taper_au_moins_10_mots: "Veuillez taper au moins 10 mots",
  equipe_admin:
    "Maintenant c’est à l’équipe administrateurs de valider votre candidature, ce processus prendra quelque jour !",
  emailandtel:
    "En cas de besoin, pouvez nous contacter par email : sur candidature@Ant’like.co Ou par tel 71 XXXXXX.",
  Notez_bien:
    "Notez bien : Une fois que votre projet est validé, vous devez lancer le formulaire de création  de votre campagne de financement, pour pouvoir recevoir les fonds. Vous pouvez créer plusieurs campagnes, mais seulement une sera ouverte pour les dons.",
  Images: "Images",
  DurabilitéTitle: "Durabilité",
  UnicitéTitle: "Unicité",
  InnovationTitle: "Innovation",
  Impact_sur_environnement: "Impact sur l'environnement",
  Valeur_communauté: "Valeur ajoutée à la communauté",
  Autres: "Autres",
  Aucune_vidéo: "Aucune vidéo",
  Réponse: "Réponse",
  Download: "Download",
  Document: "Document",
  Donner: "Donner",
  première_plateforme: "La première plateforme de‘’crowdonation’’    ",
  Adresse: "Adresse",
  Numero: "Numéro de téléphone",
  employees: "Employés",
  nombre_des_projets: "nombre des projets",
  general_informations: "Informations générales",
  durability: "Durabilité",
  employability: "Employabilité",
  innovation: "Innovation",
  uniqueness: "Unicité",
  environmental_impact: "Impact sur l'environnement",
  indirect_jobs_and_service_providers:
    "Emplois indirects et les prestataires de services",
  added_value_community: "Valeur ajoutée à la communauté",
  cofounder_project_team: "Co-fondateur et équipe du projet",
  supporting_materials_promotional_content:
    "Documents de support et contenu promotionnel",
  how_create_project: "Comment créer un projet ?",
  explanation_1:
    "Si vous souhaitez créer un projet, veuillez remplir le formulaire ci-dessous pour nous fournir des détails sur votre initiative entrepreneuriale ou sociale. Les informations que vous fournirez seront utiles pour mieux comprendre votre projet et faciliter la collecte de fonds.",
  explanation_2: "Le formulaire comporte 10 étapes, dans lesquelles vous répondrez à une série de questions qui seront comme suit :",
  explanation_3: "Une fois que vous aurez terminé de remplir le formulaire de création de projet, notre équipe d'administrateurs validera votre candidature et vous informera si des ajustements ou des optimisations sont nécessaires.",
  explanation_4: "Une fois votre projet validé, vous pourrez créer une campagne de financement participatif pour définir vos objectifs en termes d'activités et vos besoins de financement. Vous pourrez également créer un contenu spécifique pour cette campagne et le partager sur votre profil.",
  explanation_5: "Notez que vous pouvez également lancer plusieurs campagnes de financement participatif pour un même projet, mais seulement une sera ouverte pour les dons",
  pays: "Pays",
  yes: "Oui",
  no: "Non",
  good_impact: "Bon impact sur l'environnement",
  bad_impact: "Mauvais impact",
  no_impact: "Pas d'impact",
  no_value: "Aucune valeur (projet personnel)",
  little_value: "Peu de valeur",
  moyennement: "Moyennement",
  majority: "La majorité",
  video: "Vidéo",
  no_document: "Aucun document",
  none: "Aucun",
  tnd: "TND",
  day_s: "Jour(s)  ",
  hour_s: "Heure(s)  ",
  min_s: "Minute(s)  ",
  second_s: "Seconde(s)  ",
  days: "jours",
  description: "Description",
  add_file: "Ajouter un Fichier",
  inscription: "Inscription",
  all_categories: "Toutes les catégories",
  no_transcations: "Aucun transaction",
  aucun: "Aucun",
  check_number_of_employees: "Vérifier le nombre des employés",
  succes_create_quadratic_campaign: "Vous avez lancé une campagne quadratique",
  see_details: "Voir détails",
  add_project: "Ajouter un projet",
  Montant_Total:"Montant Total",
  Solidarity_Token:"Solidarity Token",
  nb_donations: "Nombre de donations",
  amount_to_give: "Montant à donner",
  
   //translate of a propos page

  our_vision:" Notre vison",
  p1:"est guidée par la conviction profonde que chaque pas, aussi petit soit-il, peut mener à de grands changements. Inspirés par la détermination de la fourmi, nous aspirons à créer un monde où chaque idée, chaque initiative, chaque projet, même les plus modestes, peuvent prendre leur envol.",
  p2 :"Nous nous engageons à être le catalyseur de ce changement, à ouvrir la voie à une nouvelle ère de développement régional en Tunisie.",
  p3 :"Notre vision est celle d'une communauté unie par la passion, la solidarité et le désir ardent de voir nos régions prospérer. Nous rêvons tous d’un avenir où chaque région est un centre vibrant d'innovation, d'opportunités et de croissance, où l'égalité des chances est une réalité pour tous.",
  p4 :"Ensemble, nous lançons une invitation à tous ceux qui partagent notre vision à rejoindre dans cette aventure collective, où chaque pas fait la différence. C'est en marchant ensemble que nous pouvons construire une meilleure réalité, région par région, pas après pas",
  p5 :"Engagement en faveur de la durabilité",
  p6 :"En privilégiant le développement durable local, nous encourageons chaque individu à investir dans les projets de sa région. En soutenant ces initiatives, nous visons à résoudre les défis actuels et futurs, participant ainsi à la durabilité à long terme.",
  p7 :"Notre approche assure une portée globale à des solutions ancrées localement.",


  contribuez:"Contribuez en toute confiance et sécurité",
  liberons :"Libérons ensemble le potentiel de nos régions",
  engagement :"Engagement vers la durabilité",
  engagement_p :"Notre utilisation de la technologie blockchain assure une visibilité totale sur chaque transaction, chaque fond collecté, et chaque projet soutenu. Chaque étape est enregistrée de manière immuable, créant ainsi une chaîne de confiance inébranlable.",
  inclusion_accessibilité :"Inclusion et Accessibilité",
  inclusion_accessibilité_p_about :"L'inclusion est notre boussole. Nous avons mis en place des mécanismes qui rendent le financement participatif accessible à tous, éliminant ainsi les barrières qui entraient autrefois l'accès au financement.",
  innovation_permanente :"Innovation Permanente",
  innovation_permanente_p :"L'innovation est notre credo. Nous ne nous contentons pas de suivre les tendances, nous les créons. Nous embrassons des technologies telles que le blockchain et le financement digitalisé pour garantir une expérience de financement de pointe.",
  protection_données:"Protection des données privées",
  protection_données_p:"Nous nous engageons à protéger vos données. Toutes les informations collectées sont sécurisées et traitées avec la plus grande confidentialité, garantissant ainsi la sécurité de chaque contribution.",
  rejoignez:"Rejoignez ",
  communauté:"la communauté",
  Abonnez_vous_propos:"Abonnez-vous pour recevoir chaque semaine le meilleur d’Antlike dans votre boite",
  utilisation_mail:"Nous utiliserons votre mail uniquement pour vous donner des nouvelles",


  //translate of a details page

  Innovation_permanente:"Innovation permanente",
  Innovation_permanente_p:"L'innovation est notre credo. Nous ne nous contentons pas de suivre les tendances, nous les créons. Nous embrassons des technologies telles que le blockchain et le financement digitalisé pour garantir une expérience de financement de pointe.",
  Transparence_totale:"Transparence totale",
  Transparence_totale_p:"Notre utilisation de la technologie blockchain assure une visibilité totale sur chaque transaction, chaque fond collecté, et chaque projet soutenu. Chaque étape est enregistrée de manière immuable, créant ainsi une chaîne de confiance inébranlable.",
  inclusion_accessibilité_p :"L'inclusion est notre boussole. Nous avons mis en place des mécanismes qui rendent le financement participatif accessible à tous, éliminant ainsi les barrières qui entraient autrefois l'accès au financement.",
  protection_données_prso:"Protection des données personnelles",
  protection_données_prso_p:"Nous nous engageons à protéger vos données. Toutes les informations collectées sont sécurisées et traitées avec la plus grande confidentialité, garantissant ainsi la sécurité de chaque contribution.",

  //translate statistics
  plateforme_financement:"La plateforme de financement participatif dans les régions",

};
