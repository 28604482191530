import { useState } from "react";
import FormStep1 from "./FormStep1";
import FormStep2 from "./FormStep2";
import { useTranslation } from "react-i18next";
import "../../../../translations/i18n";
const CreateCampaignForm = (props: any) => {
  const [next, setNext] = useState(0);
  const { t } = useTranslation();

  return (
    <div className="row" id="counter">
      <div className="col-lg-8" style={{ margin: "0 auto" }}>
        <div className="details_wrapper_area">
          <form>
            <div className="donet_amount_form_area doner_content_pbottom">
              {!next && (
                <FormStep1
                  next={() => setNext(1)}
                  projectId={props.projectId}
                />
              )}
              {next === 1 && <FormStep2 next={() => setNext(2)}/>}
              {next === 2 && (
                <div className="mt-4 mb-4 text-center">
                  <h3 className="mb-4">{t('Félecitations')} </h3>
                  <h5>{t("succes_campaign")} !</h5>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default CreateCampaignForm;
