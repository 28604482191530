import About from "./components/About";
import Banner from "./components/Banner/Banner";
import Categories from "./components/Categories/Categories";
import Campanies from "./components/Campanies";
import Cats from "./components/Categories";
import Profiles from "./components/Profiles";
import StatisticsComponent from "./statistics/statisticsComponent";
import SuccessfulCampaigns from "./components/SuccessfulCampaigns";
import RecentCampaigns from "./components/recentProject/RecentCampaigns";
import "./Home.css";
import { FooterComponent } from "../../components/footer";
import SubscriptionBox from '../subscription//SubscriptionBox';
import TransparencyPage from './components/TransparencySection/TransparencyPage';

import ComingSoon  from '../comingSoon/comingSoon';

const Home = () => {

  const launchDate = new Date('2023-07-16T00:00:00Z'); // Set your launch date here
  const currentDate = new Date();

  const isBeforeLaunch = currentDate < launchDate;
  return (
    <>
      {isBeforeLaunch ? (
        <ComingSoon />
      ) : (
        <>
      
      <Banner />
      <Categories />  
      
      <RecentCampaigns />
      <TransparencyPage />
      <StatisticsComponent />
      {/*<SuccessfulCampaigns />*/}
      {/* <Partners /> */}
      <SubscriptionBox />
      <FooterComponent/>

    </>
    )}
       </>
  );
};

export default Home;
